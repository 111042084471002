<template>
  <b-card
    class="blog-edit-wrapper"
  >
	<b-alert v-model="showInfoAlert" variant="info" class="p-2" dismissible>
      Trwa generowanie raportu...
    </b-alert>
	<b-alert v-model="showSuccessAlert" variant="success" class="p-2" dismissible>
      Raport został poprawnie wygenerowany.
    </b-alert>
    <!-- form -->
    <b-form class="mt-2" @submit.prevent="save">
      <b-row>
        <b-col md="4">
          <b-form-group
            label="Data od"
            label-for="blog-edit-slug"
            class="mb-2"
          >
            <flat-pickr
              v-model="dateFrom"
              class="form-control"
              :config="{ enableTime: false, dateFormat: 'Y-m-d', locale: 'pl'}"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label="Data do"
            label-for="blog-edit-slug"
            class="mb-2"
          >
            <flat-pickr
              v-model="dateTo"
              class="form-control"
              :config="{ enableTime: false, dateFormat: 'Y-m-d', locale: 'pl'}"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label="Sortuj według"
            label-for="blog-edit-slug"
            class="mb-2"
          >
            <select class="form-control" v-model="sortBy" style="width: 100%;">
              <option v-for="sortItem in sortArray" :value="sortItem.value">{{sortItem.name}}</option>
            </select>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            type="submit"
            class="mr-1"
          >
            Generuj raport
          </b-button>
          <b-button
            :to="{ name: 'apps-clients' }"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
          >
            Anuluj
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAlert, BAvatar, BCardText, BMediaAside, BMediaBody, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { quillEditor } from 'vue-quill-editor'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import axios from 'axios'
import flatPickr from 'vue-flatpickr-component'
import Polish from 'flatpickr/dist/l10n/pl.js'
import 'flatpickr/dist/flatpickr.css'

export default {
  components: {
    BCard,
    BMedia,
    BAlert,
    BAvatar,
    BCardText,
    BMediaAside,
    BMediaBody,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    quillEditor,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      dateFrom: '',
      dateTo: '',
      sortBy: 'city',
      sortArray: [
      	{
      		name: "Miejscowość",
      		value: "city"
      	},
      	{
      		name: "Data serwisu",
      		value: "service_date"
      	}
      ],
      snowOption: {
        theme: 'snow',
      },
      showInfoAlert: false,
      showSuccessAlert: false,
    }
  },
  created() {
  },
  methods: {
    save() {
      this.showInfoAlert = true
      const config = { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }, responseType: 'blob' };
      var data = {
        datefrom: this.dateFrom,
        dateto: this.dateTo,
        sortby: this.sortBy
      }
      axios.post(`${process.env.VUE_APP_API_URL}pdf`, data, config).then(response => {
        let blob = new Blob([response.data], { type: 'application/pdf' }),
        url = window.URL.createObjectURL(blob)

        window.open(url)
      	this.showInfoAlert = false
      	this.showSuccessAlert = true
      }).catch(error => {
        console.log(error)
      })
    },
  },
  setup() {
    const refInputEl = ref(null)
    const refPreviewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => { refPreviewEl.value.src = base64 })

    return {
      refInputEl,
      refPreviewEl,
      inputImageRenderer,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/pages/page-blog.scss';
</style>
